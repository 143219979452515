<template>
  <div class="home">
    <div class="header-row">
      <img src="@/assets/logo.svg" height="91" />

      <ul>
        <!--<li>About Us</li>-->
        <!--<li>Portfolio</li>-->
        <li class="contact-link"><a href="mailto:hello@jsgtechnology.com" target="_blank">Contact Us</a></li>
      </ul>
    </div>

    <div class="page-one">
      <div class="shield"></div>
      <div class="left-text">JSG Technology is a small digital design and development firm</div>
      <!-- <div class="right-text">We <span class="services"></span></div> -->
    </div>

    <div class="footer">
      <div>Copyright &copy; {{ year }} JSG Technology LLC</div>
      <div>All Rights Reserved</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 760px) {
  body {
    width: 100%;
  }
  .header-row {
    justify-content: center;
  }
  ul {
    display: none;
  }
  .page-one .left-text {
    position: relative !important;
    width: auto !important;
    text-align: center !important;
    left: auto !important;
  }
  .page-one .right-text {
    position: relative !important;
    width: auto !important;
    text-align: center !important;
    right: auto !important;
    top: 150px !important;
  }

  .mockup-container img {
    width: 50%;
    height: 50%;
  }
  .mockup-container .mockup-title {
    font-size: 18px;
    margin-left: 0;
  }
  .mockup-container .mockup-link {
    font-size: 12px;
  }
  .contact-modal {
    width: 100% !important;
    height: 80% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .contact-modal label {
    display: block !important;
    text-align: left !important;
    padding-left: 10px !important;
  }
  .contact-modal input {
    width: 100% !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .contact-modal textarea {
    width: 100% !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .contact-modal h2 {
    margin: 0 !important;
  }
}

body {
  font-family: 'Lato', sans-serif;
  color: #ffffff;
  margin: 0;
}

.header-row {
  width: 100%;
  height: 120px;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 2px solid #2196f2;
  font-size: 20px;

  a,
  a:visited,
  a:active {
    color: #2196f2;
    font-weight: normal;
    text-underline-offset: 4px;
    transition: 150ms all ease-in;

    &:hover {
      color: white;
      opacity: 0.8;
    }
  }
}

.page-one {
  width: 100%;
  background-image: url('../assets/bkg-image.jpg');
  background-size: cover;
  background-position: top center;
  height: 100vh;
  position: relative;
}
.page-one .shield {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0;
}

.page-one .left-text {
  position: absolute;
  font-family: Lato-Bold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: -0.25px;
  width: 359px;
  text-align: right;
  left: 10%;
  top: 80px;
}

.page-one .right-text {
  position: absolute;
  font-family: Lato-Bold;
  font-size: 28px;
  color: #ffffff;
  letter-spacing: -0.25px;
  width: 459px;
  text-align: left;
  right: 10%;
  top: 200px;
}

.page-two h2 {
  font-family: Lato-Black;
  font-size: 30px;
  color: #120f0f;
  letter-spacing: -0.37px;
  text-align: center;
  margin-top: 50px;
}

.mockup-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 90px;
}

.mockup-title {
  font-family: Lato-Black;
  font-size: 24px;
  color: #2e2b2b;
  letter-spacing: -0.25px;
  margin-top: 20px;
  margin-left: 60px;
}

.mockup-link {
  font-family: Lato-Black;
  font-size: 14px;
  color: #2197f4;
  letter-spacing: -0.25px;
  text-align: right;
}

hr {
  background: #2e2b2b;
  width: 80%;
  height: 2px;
  width: 60%;
  margin-top: 60px;
}

.footer {
  font-family: Lato-Regular;
  font-size: 10px;
  color: #302e2e;
  letter-spacing: -0.08px;
  margin: 80px auto 40px auto;
  text-align: center;
}

ul {
  list-style-type: none;
}

li {
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.services {
  color: #2196f2;
}

.contact-link {
  cursor: pointer;
}

.veil {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 500ms ease-in-out;
  pointer-events: none;
}

.veil.active {
  pointer-events: auto !important;
  background: rgba(0, 0, 0, 0.8);
}
body.modal-open {
  overflow: hidden;
}

.contact-modal {
  background: #ffffff;
  box-shadow: 2px 2px 60px 0 rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  width: 531px;
  height: 446px;
  position: fixed;
  left: 200%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 1s ease-in-out;
  padding: 0 65px;
}
.contact-modal.active {
  left: 50%;
}

.contact-modal h2 {
  font-family: Lato-Black;
  font-size: 36px;
  color: #000000;
  letter-spacing: -0.14px;
  text-align: center;
}
.contact-modal label {
  font-family: Lato-Black;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
  letter-spacing: -0.07px;
  width: 123px;
  display: inline-block;
  margin: 9px 20px;
  vertical-align: top;
  text-align: right;
}
.contact-modal input {
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  height: 28px;
  width: 247px;
  margin: 9px 0;
  font-size: 16px;
  padding-left: 10px;
}
.contact-modal textarea {
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 247px;
  margin: 9px 0;
  height: 50px;
  font-size: 16px;
  padding-left: 10px;
}
.contact-modal button {
  background: #058809;
  border-radius: 6px;
  font-family: Lato-Black;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: -0.07px;
  width: 180px;
  height: 45px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  cursor: pointer;
}
.contact-modal button:hover {
  background: #058139;
}
</style>
